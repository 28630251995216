import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Heading from 'src/components/Heading';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const TextWrapper = styled('div')`
    ${below.desktop_s} {
        ${theme.spacing.mobile._24('padding')};
        ${theme.spacing.tablet._24('padding')};
    }

    ${theme.spacing.mobile._64('padding-top')};
    ${theme.spacing.mobile._64('padding-bottom')};

    ${theme.spacing.tablet._64('padding-top')};
    ${theme.spacing.tablet._64('padding-bottom')};

    ${theme.spacing.desktop._88('padding-top')};
    ${theme.spacing.desktop._88('padding-bottom')};

    color: ${theme.color.black};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const HeroHeading = styled(Heading)`
    ${theme.spacing.mobile._16('padding-bottom')};
    ${theme.spacing.tablet._16('padding-bottom')};
    ${theme.spacing.desktop._24('padding-bottom')};

    ${theme.fontSize.sans.mobile._32};
    ${theme.fontSize.sans.tablet._32};
    ${theme.fontSize.sans.desktop._40};

    line-height: 1;
    text-align: center;
`;

const HeroText = styled(Text)`
    ${above.tablet_s} {
        max-width: 45em;
    }

    text-align: center;
    color: ${theme.color.black};
    line-height: 1.6;
`;

const HeroModuleText = ({ data }) => {
    const { heading, text } = data;
    return (
        <TextWrapper>
            <HeroHeading as="h1">{heading}</HeroHeading>
            <WysiwygWrapper textComponent={HeroText} data={text} />
        </TextWrapper>
    );
};

export default HeroModuleText;

HeroModuleText.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
};

HeroModuleText.defaultProps = {
    data: {
        heading: '',
        text: '',
    },
};
